var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{staticClass:"d-block d-md-none",attrs:{"color":"rgb(0, 172, 125)","density":"compact","height":"32px"}}),_vm._v(" "),_c('v-container',{staticClass:"py-0 fill-height",attrs:{"fluid":""}},[_c('v-row',{staticClass:"fill-height mx-0"},[_c('v-col',{staticStyle:{"background-color":"white"},attrs:{"cols":"12","md":"6"}},[_c('v-row',{staticClass:"py-20"},[_c('v-card',{staticClass:"mx-auto",attrs:{"flat":""}},[_c('v-row',[_c('v-col',{staticClass:"mx-auto pb-0 pb-md-3",attrs:{"cols":"4","md":"12"}},[_c('v-img',{staticClass:"cti-mo-icon mx-auto",attrs:{"src":"/cti_mo_logo413.png","max-width":"220px"}})],1),_vm._v(" "),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',{staticClass:"justify-center font-weight-bold"},[_c('p',[_vm._v(" デマンドタクシー"),_c('br'),_vm._v("ふれあいコスモス号")])])],1)],1)],1)],1),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown === false)?_c('v-row',{staticClass:"py-20 mt-5 mb-5"},[_c('v-card',{staticClass:"mx-auto",staticStyle:{"border-color":"white","border-size":"4px"},attrs:{"flat":"","outlined":""}})],1):_vm._e(),_vm._v(" "),_c('v-row',[_c('nuxt')],1)],1),_vm._v(" "),(_vm.$vuetify.breakpoint.smAndDown === false)?_c('v-col',{staticClass:"fill-height map-container",attrs:{"cols":"12","md":"6"}},[_c('GmapMap',{staticStyle:{"width":"100%","height":"100%"},attrs:{"options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: true,
            disableDefaultUi: true
          },"center":_vm.centerCoordinates,"zoom":15}})],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }