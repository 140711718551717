export const getCenterCoordinates = () => {
  const center: any = {
    lat: 36.8065925,
    lng: 138.207102
  }
  return center
}
export const getDefaultZoomLevel = () => {
  const zoom: number = 14
  return zoom
}
